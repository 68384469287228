<template>
  <div class="credit-page">
    <el-card shadow="never">
      <div slot="header" class="clearfix">
        <div class="page-header">
          <i class="page-header-title" style="margin-right: 16px"></i>推广分销
          <!-- <el-button style="float: right; padding: 3px 0" type="text"
            >操作按钮</el-button
          > -->
        </div>
      </div>

      <el-alert type="success" show-icon>
        <p style="margin-bottom: 0px">温馨提示:</p>
        <p>
          1、由您邀请注册的用户每次下单成交的订单，你可以得到一定比例的佣金；
        </p>
        <p>2、佣金可以自由结算和提现；</p>
      </el-alert>

      <el-form
        label-position="left"
        label-width="80px"
        style="margin-top: 20px"
      >
        <el-form-item label="邀请码">
          <el-input v-model="inviteCode"></el-input>
        </el-form-item>

        <el-form-item label="邀请链接">
          <el-input v-model="inviteUrl"></el-input>
        </el-form-item>

        <el-form-item label="二维码">
          <p class="content-p">
            <img :src="inviteUrlQrImageUrl" alt="" />
          </p>
        </el-form-item>
      </el-form>

      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="一级下家" name="first" :item="0">
          <el-table
            border
            ref="singleTable"
            :data="lists[0]"
            highlight-current-row
            style="width: 100%"
          >
            <el-table-column type="datelineReadable" label="注册时间" />
            <el-table-column property="amount" label="用户名" />
            <el-table-column property="typeText" label="下家数" />
          </el-table>
          <Pagination
            @handleNumber="changeNumber($event)"
            :number="number[0]"
            :item="0"
          />
        </el-tab-pane>
        <el-tab-pane label="二级下家" name="second" :item="1">
          <el-table
            border
            ref="singleTable"
            :data="lists[0]"
            highlight-current-row
            style="width: 100%"
          >
            <el-table-column type="datelineReadable" label="注册时间" />
            <el-table-column property="amount" label="用户名" />
            <el-table-column property="typeText" label="下家数" />
          </el-table>
          <Pagination
            @handleNumber="changeNumber($event)"
            :number="number[1]"
            :item="1"
          />
        </el-tab-pane>
        <el-tab-pane label="三级下家" name="third" :item="2">
          <el-table
            border
            ref="singleTable"
            :data="lists[0]"
            highlight-current-row
            style="width: 100%"
          >
            <el-table-column type="datelineReadable" label="注册时间" />
            <el-table-column property="amount" label="用户名" />
            <el-table-column property="typeText" label="下家数" />
          </el-table>
          <Pagination
            @handleNumber="changeNumber($event)"
            :number="number[2]"
            :item="2"
          />
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import { mapState } from "vuex";
export default {
  data() {
    return {
      activeName: "first",
      number: [1, 1, 1],
      lists: [[], [], []],
      moneyData: [50, 100, 200, 500],
      payType: [
        {
          img: require("@/assets/image/weixin.png"),
          title: "微信",
          value: "wxpay",
        },
        {
          img: require("@/assets/image/alipay.png"),
          title: "支付宝",
          value: "alipay",
        },
        {
          img: require("@/assets/image/paypal.png"),
          title: "Paypal",
          value: "paypal",
        },
      ],
      from: {
        amount: 0,
        verifyCode: null,
        verifyCodeType: 1,
        platform: "wxpay",
        platformAccount: "",
        platformPerson: "",
      },
      CodeType: [
        { name: "邮箱", id: 1 },
        { name: "手机", id: 2 },
      ],
      valuePay: "微信",
    };
  },
  components: {
    Pagination,
  },
  computed: {
    ...mapState({
      balanceTotal: (state) => state.balance.balanceTotal,
      invited_list: (state) => state.user.invited_list,
      inviteCode: (state) => state.user.inviteCode,
      inviteUrl: (state) => state.user.inviteUrl,
      inviteUrlQrImageUrl: (state) => state.user.inviteUrlQrImageUrl,
    }),
  },
  created() {
    //加载用户信息
    this.$store.dispatch("user/info");

    this.loadData({ item: 0, number: 1 });
  },
  methods: {
    handleCash() {
      this.$store.dispatch("withdraw/create", { ...this.from });
    },
    handleCode() {
      this.$store.dispatch("withdraw/code", {
        verifyCodeType: this.from.verifyCodeType,
      });
    },
    changePayType(value, type) {
      console.log(value);
      this.valuePay = value;
      this.from.platform = type;
    },
    handleClick(tab) {
      this.loadData({ item: tab.$attrs.item, number: 1 });
    },
    loadData(value) {
      this.number[value.item] = value.number;
      let data = { offset: (this.number[value.item] - 1) * 10, limit: 10 };
      data.inviterLevel = value.item + 1;
      this.$store.dispatch("user/invited_list", data).then(() => {
        // console.log(value);
        this.lists[value.item] = this.list;
        // console.log(this.lists[value.item]);
        // this.$forceUpdate();
      });
    },
  },
};
</script>

<style scoped>
.back-img {
  height: 20px;
  margin-right: 10px;
}
.back-btn {
  width: 140px;
  height: 40px;
  color: #5f5f5f;
  border: 2px solid #5f5f5f;
  line-height: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-right: 20px;
  font-size: 14px;
}
.back-btn.active {
  border: 2px solid #014785;
}
p {
  padding: 0px;
  margin: 0px;
  color: #5f5f5f;
  font-size: 12px;
}
.item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}
.title {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 23px;
  color: #1b1b1b;
  opacity: 1;
}
.content {
  margin-left: 20px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 22px;
  color: #1b1b1b;
  opacity: 1;
}
.input {
  margin-left: 10px;
  margin-right: 10px;
}
.item.active {
  background: #f8f8f8;
  padding: 10px 20px;
  margin-right: 40px;
  box-sizing: border-box;
  /* padding-right: 40px;  */
  /* width: auto; */
}
.input-title {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 23px;
  color: #1b1b1b;
  opacity: 1;
  margin-left: 20px;
}
</style>
